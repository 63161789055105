import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { makeStyles } from '@material-ui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Chip,
  Button,
  IconButton,
  Box,
  Tooltip,
  Theme
} from '@material-ui/core'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { useSnackbar } from 'material-ui-snackbar-provider'
import Moment from 'react-moment'
import Image from 'next/image'
import {
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Star as StarIcon,
  Edit as EditIcon
} from '@material-ui/icons'
import { useTranslation } from 'next-i18next'

import { CONCERT_TYPES, CONCERT_COLOURS } from '../constants/concertTypes'
import { Concert, Timestamp } from '../types/types'
import { getThumbnailUrl } from '../utils/thumbnail'
import {
  BACKSTAGE_CONCERT_LIST_PATH,
  CONCERTSERIES_DETAILS_PATH,
  CONCERT_DETAILS_PATH,
  ENSEMBLE_DETAILS_PATH,
  FESTIVAL_DETAILS_PATH,
  VENUE_DETAILS_PATH
} from '../constants/paths'
import { useRegion } from '../hooks/useRegion'
import { getAbsoluteUrl } from '../utils/absoluteUrl'

const concertColours = () => {
  let chipColours

  CONCERT_TYPES.map(
    (type, index) =>
      (chipColours = {
        ...chipColours,
        [type]: {
          color: CONCERT_COLOURS[type],
          background: CONCERT_COLOURS[type] + '1A'
        }
      })
  )

  return chipColours
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // OVERALL CARD PROPERTIES
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135929',
    color: '#081359',
    whiteSpace: 'pre-wrap'
  },
  summary: {
    minHeight: '192px',
    padding: theme.spacing(0.5, 2),
    overflow: 'hidden',
    borderRadius: '8px',
    cursor: 'default !important'
  },
  // GENERAL LAYOUT AND CONTAINERS
  imgContainer: {
    position: 'relative',
    margin: theme.spacing(0, 3, 0, 0),
    width: '80px',
    height: '80px',
    borderRadius: '4px',
    overflow: 'hidden'
  },
  infoContainer: {
    maxWidth: '128px',
    minWidth: '104px'
  },
  titleContainer: {
    maxHeight: '70px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  artistContainer: {
    width: '100%',
    // maxHeight: '52px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  buttonContainer: {
    minWidth: '150px',
    maxWidth: '150px'
  },
  shareContainer: {
    marginRight: theme.spacing(3),
    width: '128px'
  },
  descriptionContainer: {
    overflow: 'hidden',
    maxWidth: '600px'
  },
  // VIP STAR AND TOOLTIP
  star: {
    position: 'absolute',
    height: '20px',
    color: '#F6E085',
    top: '4px',
    left: '2px',
    zIndex: 1
  },
  triangle: {
    height: 0,
    width: 0,
    borderRight: '45px solid transparent',
    borderTop: '45px solid #000000B3',
    position: 'absolute',
    top: 0,
    left: 0
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914'
  },
  tooltipArrow: {
    color: theme.palette.primary.main
  },
  tooltipHeading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  // BASIC ELEMENTS
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  time: {
    fontSize: '1.4rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  date: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  festivalOrConcertseries: {
    fontSize: '0.8125rem',
    color: theme.palette.primary.main,
    lineHeight: '1rem',
    wordBreak: 'break-word'
  },
  location: {
    fontSize: '0.8125rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    wordBreak: 'break-word'
  },
  city: {
    fontSize: '0.8125rem',
    color: '#8187AD'
  },
  title: {
    display: 'inline',
    width: '100%',
    maxWidth: '700px',
    fontSize: '1.2rem',
    // lineHeight: '1.5rem',
    color: '#081359'
  },
  artist: {
    display: 'inline',
    width: '100%',
    maxWidth: '700px',
    fontSize: '1rem',
    // lineHeight: '1.5rem',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  artistLink: {
    display: 'inline !important'
  },
  chip: {
    marginRight: theme.spacing(2),
    fontSize: '0.7rem',
    height: '24px',
    textTransform: 'capitalize'
  },
  secondaryHeading: {
    fontSize: '0.8125rem',
    color: '#081359',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  details: {
    padding: theme.spacing(0, 2, 2, 2),
    display: 'block'
  },
  // SHARE BUTTONS
  shareButton: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    lineHeight: 1.5,
    flex: 'auto 0 0',
    width: '32px',
    height: '32px',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    border: '0',
    borderRadius: '8px',
    padding: 0,
    margin: theme.spacing(0, 1, 1, 0),
    overflow: 'visible',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0px 3px 11px #08135929'
    }
  },
  externalLink: {
    color: '#000000'
  },
  // BUTTONS
  button: {
    width: 'calc(50% - 8px)',
    maxWidth: '336px',
    fontSize: '0.8rem',
    padding: theme.spacing(1, 0),
    borderRadius: '4px',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 3px 11px #08135929'
    }
  },
  editButton: {
    display: 'flex',
    alignItems: 'center'
  },
  editIcon: {
    marginRight: theme.spacing(1),
    paddingBottom: '2px'
  },
  readMoreIcon: {
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  rotateIcon: {
    transform: 'rotate(180deg)'
  },
  // GENERAL UTILITY
  rounded: {
    '&:last-child': {
      borderRadius: '8px'
    },
    '&:before': {
      content: 'none'
    }
  },
  mw: {
    maxWidth: '600px',
    minWidth: 0
  },
  py2: {
    padding: theme.spacing(2, 0)
  },
  pt2: {
    paddingTop: theme.spacing(2)
  },
  mtneg1: {
    marginTop: theme.spacing(-1)
  },
  displayAbove768: {
    display: 'none'
  },
  displayBelow768: {
    display: 'flex'
  },
  displayBelow600: {},
  displayAbove600: {},
  // MEDIA QUERIES
  '@media screen and (min-width: 600px)': {
    summary: {
      minHeight: '160px'
    },
    imgContainer: {
      width: '128px',
      height: '128px'
    },
    star: {
      height: '24px',
      top: '7px',
      left: '8px'
    },
    triangle: {
      borderRight: '65px solid transparent',
      borderTop: '60px solid #000000B3'
    },
    button: {
      padding: theme.spacing(1, 0)
    },
    displayAbove600: {
      display: 'flex'
    },
    displayBelow600: {
      display: 'none'
    }
  },
  '@media screen and (max-width: 600px)': {
    displayAbove600: {
      display: 'none'
    },
    displayBelow600: {
      display: 'flex'
    }
  },
  '@media screen and (min-width: 768px)': {
    details: {
      padding: theme.spacing(2)
    },
    button: {
      padding: theme.spacing(1.5, 0),
      width: '100%',
      fontSize: '1rem',
      borderRadius: '8px'
    },
    displayAbove768: {
      display: 'flex'
    },
    displayBelow768: {
      display: 'none'
    }
  },
  '@media screen and (min-width: 960px)': {
    summary: {
      height: '192px'
    },
    imgContainer: {
      width: '160px',
      height: '160px'
    },
    infoContainer: {
      maxWidth: '160px'
    },
    buttonContainer: {
      minWidth: '190px',
      maxWidth: '190px'
    },
    shareContainer: {
      width: '160px'
    },
    chip: {
      fontSize: '0.8125rem',
      height: '32px'
    },
    button: {
      padding: theme.spacing(2.5, 0)
    },
    time: {
      fontSize: '2rem'
    },
    date: {
      fontSize: '1.5rem'
    },
    festivalOrConcertseries: {
      lineHeight: '1.25rem',
      fontSize: '1rem',
      wordBreak: 'break-word'
    },
    location: {
      fontSize: '1rem',
      wordBreak: 'break-word'
    },
    city: {
      fontSize: '1rem'
    },
    title: {
      fontSize: '1.5rem'
    },
    artist: {
      fontSize: '1.25rem'
    },
    shareButton: {
      width: '40px',
      height: '40px'
    },
    secondaryHeading: {
      fontSize: '1rem'
    }
  }
}))

const useConcertStyles = makeStyles((theme) => concertColours())

interface ConcertCardProps {
  concertId: string
  concert: Omit<Concert, 'createdAt' | 'updatedAt'>
  editable?: boolean
  startsExpanded?: boolean
}

const ConcertCard: React.FC<ConcertCardProps> = ({
  concertId,
  concert,
  editable = false,
  startsExpanded = false
}) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const concertClasses = useConcertStyles()
  const snackbar = useSnackbar()
  const region = useRegion()

  const shareUrl = getAbsoluteUrl(CONCERT_DETAILS_PATH(region, concert.slug))

  const toggleExpanded = () => setExpanded((v) => !v)
  useEffect(() => startsExpanded && setExpanded(true), [startsExpanded])

  const VipStar = () => (
    <>
      <Tooltip
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        title={
          <>
            <Typography className={classes.tooltipHeading}>
              {t('Star-marked concert')}
            </Typography>
            <Typography variant='body1'>
              {t(
                'This concert is provided by one of the major ensembles or venues in the country'
              )}
            </Typography>
          </>
        }
      >
        <StarIcon className={classes.star} />
      </Tooltip>
      <div className={classes.triangle} />
    </>
  )

  const TimeAndDate = () => (
    <div>
      <Typography variant='h3' className={classes.time}>
        <Moment
          unix
          date={(concert.dateTime as Timestamp)?.seconds ?? concert.dateTime}
          format='D. MMM'
        />
      </Typography>
      <Typography variant='h3' className={classes.date}>
        <Moment
          unix
          date={(concert.dateTime as Timestamp)?.seconds ?? concert.dateTime}
          format='HH:mm'
        />
      </Typography>
    </div>
  )

  const FestivalOrConcertseries = () => (
    <div>
      {concert.festival?.slug ? (
        <Link
          href={FESTIVAL_DETAILS_PATH(
            concert.festival.location?.countryCode?.toLowerCase() ?? region,
            concert.festival.slug
          )}
          passHref
        >
          <a>
            <Typography
              variant='h6'
              className={classes.festivalOrConcertseries}
            >
              {concert.festival.name}
            </Typography>
          </a>
        </Link>
      ) : concert.concertseries?.slug ? (
        <Link
          href={CONCERTSERIES_DETAILS_PATH(
            concert.concertseries.location?.countryCode?.toLowerCase() ??
              region,
            concert.concertseries.slug
          )}
          passHref
        >
          <a>
            <Typography
              variant='h4'
              className={classes.festivalOrConcertseries}
            >
              {concert.concertseries.name}
            </Typography>
          </a>
        </Link>
      ) : null}
    </div>
  )

  const Location = () => (
    <div>
      {concert.venue.slug ? (
        <Link
          href={VENUE_DETAILS_PATH(
            concert.venue.location?.countryCode?.toLowerCase() ?? region,
            concert.venue.slug
          )}
          passHref
        >
          <a>
            <Typography variant='h3' className={classes.location} gutterBottom>
              {concert.venue.name}
            </Typography>
          </a>
        </Link>
      ) : (
        <Typography variant='h3' className={classes.location} gutterBottom>
          {concert.venue.name}
        </Typography>
      )}
      <Typography variant='h3' className={classes.city}>
        {concert.venue.location.city}
      </Typography>
    </div>
  )

  const TitleAndArtist = () => (
    <div>
      <div className={classes.titleContainer}>
        <Link href={CONCERT_DETAILS_PATH(region, concert.slug)} passHref>
          <a>
            <Typography className={classes.title} component='h1'>
              {concert.title}
            </Typography>
          </a>
        </Link>
      </div>
      <div className={classes.artistContainer}>
        {concert.ensembleIds?.map((id, i) => {
          const ensemble = concert.ensembles[id]

          if (!ensemble) return null

          return [
            i > 0 && ', ',
            <Link
              key={id}
              href={ENSEMBLE_DETAILS_PATH(
                ensemble.location.countryCode.toLowerCase() ?? region,
                ensemble.slug
              )}
              passHref
            >
              <a>
                <Typography variant='h2' className={classes.artist}>
                  {ensemble.name}
                </Typography>
              </a>
            </Link>
          ]
        })}
      </div>
      <div className={classes.artistContainer}>
        {(concert.artists ?? []).map((artist, i) => [
          i > 0 && ', ',
          <Typography variant='h2' key={i} className={classes.artist}>
            {artist}
          </Typography>
        ])}
      </div>
    </div>
  )

  const Genres: React.VFC<{ SM?: boolean; LG?: boolean }> = ({ SM, LG }) => (
    <div
      className={`${LG && classes.displayAbove600} ${
        SM && classes.displayBelow600
      } ${SM && classes.py2}`}
    >
      {concert.types.map((type) => (
        <Chip
          key={type}
          label={t(type)}
          className={`${classes.chip} ${concertClasses[type]}`}
        />
      ))}
    </div>
  )

  const primaryButton = editable ? (
    <Link href={`${BACKSTAGE_CONCERT_LIST_PATH(region)}/${concertId}`} passHref>
      <Button
        variant='contained'
        className={`${classes.button}`}
        color='primary'
        disableRipple
      >
        <div className={classes.editButton}>
          <EditIcon fontSize='small' className={classes.editIcon} /> {t('Edit')}
        </div>
      </Button>
    </Link>
  ) : (
    <Button
      variant='contained'
      className={`${classes.button}`}
      color='primary'
      href={concert.ticketUrl}
      target='_blank'
      rel='noopener'
      disableRipple
    >
      {t('Find ticket')}
    </Button>
  )

  const SecondaryButton = () => (
    <Button
      onClick={toggleExpanded}
      variant='contained'
      className={classes.button}
      color='secondary'
      endIcon={
        <ExpandMoreIcon
          fontSize='small'
          className={`${expanded && classes.rotateIcon} ${
            classes.readMoreIcon
          }`}
        />
      }
      disableRipple
    >
      {expanded ? t('Read less') : t('Read more')}
    </Button>
  )

  const ShareButtons = () => (
    <div>
      <div>
        <Typography
          variant='h3'
          className={classes.secondaryHeading}
          gutterBottom
        >
          {t('Share')}
        </Typography>
      </div>
      <div>
        <IconButton
          className={`${classes.shareButton} ${classes.mtneg1}`}
          component='button'
          aria-label='share'
          onClick={() => {
            navigator.clipboard.writeText(shareUrl)
            snackbar.showMessage(t('Copied concert link to clipboard'))
          }}
        >
          <LinkIcon color='primary' />
        </IconButton>
        <FacebookShareButton
          className={classes.shareButton}
          aria-label='facebook'
          url={shareUrl}
        >
          <FacebookIcon color='primary' />
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.shareButton}
          aria-label='twitter'
          url={shareUrl}
        >
          <TwitterIcon color='primary' />
        </TwitterShareButton>
      </div>
    </div>
  )

  return (
    <div className={classes.root} key={concertId}>
      <Accordion
        expanded={expanded}
        className={classes.rounded}
        elevation={0}
        square
      >
        <AccordionSummary className={classes.summary}>
          <Grid container style={{ minWidth: 0 }}>
            <div className={`${classes.imgContainer}`}>
              <Image
                layout='fill'
                className={classes.image}
                src={getThumbnailUrl(concert.photoUrl)}
                alt={concert.title}
              />
              {concert.user?.isVip && <VipStar />}
            </div>
            <Grid
              container
              className={`${classes.displayAbove600} ${classes.infoContainer}`}
              direction='column'
              justifyContent='space-between'
            >
              <TimeAndDate />
              <FestivalOrConcertseries />
              <Location />
            </Grid>
            <Grid
              container
              item
              xs
              direction='column'
              justifyContent='space-between'
              style={{ minWidth: 0, overflow: 'hidden', marginRight: '8px' }}
            >
              <TitleAndArtist />
              <Genres LG />
            </Grid>
            <Grid xs={12} item className={classes.displayBelow600}>
              {/* 80 + 24 is width of the image on small screens + margin */}
              <div style={{ marginLeft: 104, marginTop: 8 }}>
                <FestivalOrConcertseries />
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={`${classes.displayBelow600} ${classes.pt2}`}
            >
              <div className={classes.infoContainer}>
                <TimeAndDate />
              </div>
              <Grid item xs>
                <Location />
              </Grid>
            </Grid>
            <div
              className={`${classes.displayAbove768} ${classes.buttonContainer}`}
            >
              <Grid container direction='column' justifyContent='space-between'>
                {primaryButton}
                <SecondaryButton />
              </Grid>
            </div>
            <Grid
              container
              item
              xs={12}
              className={`${classes.displayBelow768} ${classes.pt2}`}
              justifyContent='space-between'
              alignItems='flex-start'
            >
              {primaryButton}
              <SecondaryButton />
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          <Genres SM />
          <Grid container>
            <div
              className={`${classes.shareContainer} ${classes.displayAbove600}`}
            >
              <ShareButtons />
            </div>
            <Grid item className={classes.descriptionContainer}>
              <Typography
                variant='h3'
                className={classes.secondaryHeading}
                gutterBottom
              >
                {t('Address')}
              </Typography>
              <Typography variant='body1' paragraph gutterBottom>
                <a
                  className={classes.externalLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://maps.google.com/?q=${concert.venue.location.street}, ${concert.venue.location.zipcode} ${concert.venue.location.city}`}
                >
                  {concert.venue.location.street}, <br />
                  {concert.venue.location.zipcode} {concert.venue.location.city}
                </a>
              </Typography>
              <Typography
                variant='h3'
                className={classes.secondaryHeading}
                gutterBottom
              >
                {t('Description')}
              </Typography>
              <Typography variant='body1' paragraph>
                {concert.description}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.displayBelow600}>
            <ShareButtons />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ConcertCard
