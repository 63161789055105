export const getThumbnailUrl = (originalUrl: string): string => {
  if (originalUrl.includes('.jpg?alt')) {
    return originalUrl.replace('.jpg?alt', '_500x500.jpg?alt')
  } else if (originalUrl.includes('.jpeg?alt')) {
    return originalUrl.replace('.jpeg?alt', '_500x500.jpeg?alt')
  } else if (originalUrl.includes('.png?alt')) {
    return originalUrl.replace('.png?alt', '_500x500.png?alt')
  } else {
    return originalUrl.replace('?alt', '_500x500?alt')
  }
}
