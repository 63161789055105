export const CONCERT_TYPES = [
  'symphonic',
  'soloduo',
  'earlymusic',
  'chamber',
  'opera',
  'ballet',
  'church',
  'youth',
  'student',
  'avantgarde',
  'song',
  'crossover',
  'churchmusic'
]

export const CONCERT_COLOURS = {
  symphonic: '#9A6548',
  soloduo: '#EF6C00',
  earlymusic: '#D84315',
  opera: '#CA4E20',
  ballet: '#344C72',
  chamber: '#4D3A33',
  church: '#B94449',
  youth: '#008381',
  student: '#9A1F1F',
  avantgarde: '#375365',
  song: '#9D155C',
  crossover: '#51387B',
  churchmusic: '#6A6550'
}
